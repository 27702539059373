import i18n, { Lang } from "../i18n";

const simpleLang = (lang: string) => lang.match(/([a-z]{2})(-[a-z]{2})?/)[1];

export const linkResolver = (doc) => {
  const paths = i18n[doc.lang as Lang].paths;

  if (doc.type === "cabin") {
    return `/${simpleLang(doc.lang)}/${paths.cabin}/${doc.uid}`;
  }

  if (doc.type === "frontpage") {
    return `/${simpleLang(doc.lang)}/`;
  }

  if (doc.type === "cabins") {
    return `/${simpleLang(doc.lang)}/${paths.cabins}`;
  }

  if (doc.type === "gallery") {
    return `/${simpleLang(doc.lang)}/${paths.gallery}`;
  }

  if (doc.type === "toijala_farm") {
    return `/${simpleLang(doc.lang)}/${paths.farm}`;
  }

  if (doc.type === "services") {
    return `/${simpleLang(doc.lang)}/${paths.services}`;
  }

  if (doc.type === "history") {
    return `/${simpleLang(doc.lang)}/${paths.history}`;
  }

  return "/";
};
