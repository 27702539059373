// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-basic-tsx": () => import("./../../../src/templates/basic.tsx" /* webpackChunkName: "component---src-templates-basic-tsx" */),
  "component---src-templates-cabin-tsx": () => import("./../../../src/templates/cabin.tsx" /* webpackChunkName: "component---src-templates-cabin-tsx" */),
  "component---src-templates-cabins-tsx": () => import("./../../../src/templates/cabins.tsx" /* webpackChunkName: "component---src-templates-cabins-tsx" */),
  "component---src-templates-frontpage-tsx": () => import("./../../../src/templates/frontpage.tsx" /* webpackChunkName: "component---src-templates-frontpage-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */)
}

