export type Lang = "fi" | "en-gb" | "de-de";

type Translations = {
  frontPage: {
    rentACabin: string;
    cabins: string;
    address: string;
    phoneNumber: string;
    email: string;
    language: string;
  };
  paths: {
    cabin: string;
    cabins: string;
    farm: string;
    gallery: string;
    services: string;
    history: string;
  };
  languages: {
    inFinnish: string;
    inEnglish: string;
    inGerman: string;
  };
  cabin: {
    pricePerWeek: string;
    persons: string;
    readMore: string;
  };
  reservationForm: {
    general: {
      message: string;
      description: string;
    };
    send: string;
    reservationSent: {
      message: string;
      description: string;
      reserveAgain: string;
    };
    reservationFailed: {
      message: string;
      description: string;
    };
    dates: {
      title: string;
      errors: {
        required: string;
        startBeforeEnd: string;
      };
    };
    cabin: {
      title: string;
    };
    startDate: {
      title: string;
    };
    endDate: {
      title: string;
    };
    price: {
      title: string;
      placeholder: string;
      weekendPrice: string;
      weekPrice: string;
      errors: {
        missingPrice: string;
        onlyWeeks: string;
      };
    };
    name: {
      title: string;
      placeholder: string;
      errors: {
        required: string;
      };
    };
    numberOfPersons: {
      title: string;
      errors: {
        required: string;
      };
    };
    email: {
      title: string;
      placeholder: string;
      errors: {
        required: string;
        validEmail: string;
      };
    };
    phone: {
      title: string;
      placeholder: string;
      errors: {
        required: string;
      };
    };
    recaptcha: {
      title: string;
      errors: {
        required: string;
      };
    };
    extra: {
      title: string;
      placeholder: string;
    };
  };
};

type I18N = { [lang in Lang]: Translations };

const i18n: I18N = {
  fi: {
    frontPage: {
      cabins: "Mökit",
      rentACabin: "Vuokraa mökki",
      address: "Osoite",
      phoneNumber: "Puhelin",
      email: "Sähköposti",
      language: "Kieli",
    },
    paths: {
      cabin: "mokki",
      cabins: "mokit",
      farm: "toijalan-tila",
      gallery: "galleria",
      services: "palvelut",
      history: "historia",
    },
    languages: {
      inFinnish: "Suomeksi",
      inEnglish: "In English",
      inGerman: "Auf Deutsch",
    },
    cabin: {
      pricePerWeek: "€/vko",
      persons: "hlö",
      readMore: "Lue lisää…",
    },
    reservationForm: {
      general: {
        message: "Ota yhteyttä",
        description:
          "<p>Voit tehdä alustavan varauksen tarkistamalla vapaan ajankohdan ja täyttämällä alla olevan varauslomakkeen. Saat samalla alustavan hinnan varauksellesi.</p><p>Viikonloput vuokrataan pääsääntöisesti perjantaista sunnuntaihin.</p><p>Kesäaikana 1.6.-31.8. on mahdollista varata vain viikkoja ja viikkovaraukset ovat sunnuntaista sunnuntaihin.</p>",
      },
      send: "Lähetä alustava varauspyyntö",
      reservationSent: {
        message: "Varauspyyntö lähetetty",
        description: "Kiitos alustavasta varauksesta! Otamme sinuun yhteyttä!",
        reserveAgain: "Tee toinen varaus",
      },
      reservationFailed: {
        message: "Varauspyynnön lähettäminen epäonnistui",
        description:
          "Varauspyynnön lähettäminen epäonnistui. Yritä uudestaan tai ota yhteyttä puhelimella tai sähköpostilla.",
      },
      dates: {
        title: "Tulo- ja lähtöpäivä",
        errors: {
          required: "Tulo- ja lähtöpäivä ovat pakollisia tietoja",
          startBeforeEnd: "Tulopäivän tulee olla ennen lähtöpäivää.",
        },
      },
      cabin: {
        title: "Mökki",
      },
      startDate: {
        title: "Tulopäivä",
      },
      endDate: {
        title: "Lähtöpäivä",
      },
      price: {
        title: "Hinta",
        placeholder: "Syötä tulo- ja lähtöpäivä",
        weekendPrice: "Viikonloppuhinta",
        weekPrice: "Viikkohinta",
        errors: {
          missingPrice: "Ei hintatietoa valitulle ajalle. Saat tarjouksen.",
          onlyWeeks:
            "Valittuna ajanjaksona vuokrataan vain kokonaisia viikkoja",
        },
      },
      numberOfPersons: {
        title: "Henkilömäärä",
        errors: {
          required: "Henkilömäärä on pakollinen tieto",
        },
      },
      name: {
        title: "Nimi",
        placeholder: "Etunimi Sukunimi",
        errors: {
          required: "Nimi on pakollinen tieto",
        },
      },
      email: {
        title: "Sähköpostiosoite",
        placeholder: "etunimi.sukunimi@example.com",
        errors: {
          required: "Sähköpostiosoite on pakollinen tieto",
          validEmail: "Anna oikea sähköpostiosoite",
        },
      },
      phone: {
        title: "Puhelinnumero",
        placeholder: "+358 40 1234 567",
        errors: {
          required: "Puhelinnumero on pakollinen tieto",
        },
      },
      recaptcha: {
        title: "Varmistus",
        errors: {
          required: "Ole hyvä ja tee varmistus loppuun.",
        },
      },
      extra: {
        title: "Lisätietoja",
        placeholder:
          "Lisätietoja tai toiveita lisäpalveluista kuten palju, erityissaunat tai muuta tekemistä loman ajaksi.",
      },
    },
  },
  "en-gb": {
    frontPage: {
      cabins: "Cabins",
      rentACabin: "Rent a cabin",
      address: "Address",
      phoneNumber: "Phone number",
      email: "Email",
      language: "Language",
    },
    paths: {
      cabin: "cabin",
      cabins: "cabins",
      farm: "farm",
      gallery: "gallery",
      services: "services",
      history: "history",
    },
    languages: {
      inFinnish: "Suomeksi",
      inEnglish: "In English",
      inGerman: "Auf Deutsch",
    },
    cabin: {
      pricePerWeek: "€/wk",
      persons: "persons",
      readMore: "Read more…",
    },
    reservationForm: {
      general: {
        message: "Making a preliminary booking",
        description:
          "<p>Check available dates and fill the form for preliminary booking of cottage. You will also get a preliminary price for your booking.</p><p>During summer season 1.6.-31.8. it is possible to book only full weeks from Sunday to Sunday.</p><p>Weekends are mainly booked from Friday to Sunday.</p>",
      },
      send: "Send preliminary booking",
      reservationSent: {
        message: "Booking sent",
        description:
          "Thank you for the preliminary booking. We will contact you!",
        reserveAgain: "Make another reservation",
      },
      reservationFailed: {
        message: "Sending booking failed",
        description: "Try again or contact via phone or email..",
      },
      dates: {
        title: "Check-in and check-out dates",
        errors: {
          required: "Check-in and check-out dates are required",
          startBeforeEnd: "Check-in date must be before check-out date.",
        },
      },
      cabin: {
        title: "Cabin",
      },
      startDate: {
        title: "Check-in date",
      },
      endDate: {
        title: "Check-out date",
      },
      price: {
        title: "Price",
        placeholder: "Please input check-in and check-out dates",
        weekendPrice: "Weekend price",
        weekPrice: "Week price",
        errors: {
          missingPrice: "No price information. You will receive an offer.",
          onlyWeeks: "Only full weeks can be reserved.",
        },
      },
      numberOfPersons: {
        title: "Number of persons",
        errors: {
          required: "Number of persons is required",
        },
      },
      name: {
        title: "Name",
        placeholder: "John Doe",
        errors: {
          required: "Name is required",
        },
      },
      email: {
        title: "Email",
        placeholder: "john.doe@example.com",
        errors: {
          required: "Email is required",
          validEmail: "Input valid email address",
        },
      },
      phone: {
        title: "Phone number",
        placeholder: "+358 40 1234 567",
        errors: {
          required: "Phone number is required",
        },
      },
      recaptcha: {
        title: "Verification",
        errors: {
          required: "Please fulfill the verification.",
        },
      },
      extra: {
        title: "Additional information",
        placeholder:
          "Additional information or requests for additional services like outdoor hot tub, special saunas or other activities during your stay.",
      },
    },
  },
  "de-de": {
    frontPage: {
      cabins: "Cabins",
      rentACabin: "Rent a cabin",
      address: "Address",
      phoneNumber: "Phone number",
      email: "Email",
      language: "Language",
    },
    paths: {
      cabin: "cabin",
      cabins: "cabins",
      farm: "farm",
      gallery: "gallery",
      services: "services",
      history: "history",
    },
    languages: {
      inFinnish: "Suomeksi",
      inEnglish: "In English",
      inGerman: "Auf Deutsch",
    },
    cabin: {
      pricePerWeek: "€/wk",
      persons: "persons",
      readMore: "Read more…",
    },
    reservationForm: {
      general: {
        message: "Making a preliminary booking",
        description:
          "<p>Check available dates and fill the form for preliminary booking of cottage. You will also get a preliminary price for your booking.</p><p>During summer season 1.6.-31.8. it is possible to book only full weeks from Sunday to Sunday.</p><p>Weekends are mainly booked from Friday to Sunday.</p>",
      },
      send: "Send preliminary booking",
      reservationSent: {
        message: "Booking sent",
        description:
          "Thank you for the preliminary booking. We will contact you!",
        reserveAgain: "Make another reservation",
      },
      reservationFailed: {
        message: "Sending booking failed",
        description: "Try again or contact via phone or email..",
      },
      dates: {
        title: "Check-in and check-out dates",
        errors: {
          required: "Check-in and check-out dates are required",
          startBeforeEnd: "Check-in date must be before check-out date.",
        },
      },
      cabin: {
        title: "Cabin",
      },
      startDate: {
        title: "Check-in date",
      },
      endDate: {
        title: "Check-out date",
      },
      price: {
        title: "Price",
        placeholder: "Please input check-in and check-out dates",
        weekendPrice: "Weekend price",
        weekPrice: "Week price",
        errors: {
          missingPrice: "No price information. You will receive an offer.",
          onlyWeeks: "Only full weeks can be reserved.",
        },
      },
      numberOfPersons: {
        title: "Number of persons",
        errors: {
          required: "Number of persons is required",
        },
      },
      name: {
        title: "Name",
        placeholder: "John Doe",
        errors: {
          required: "Name is required",
        },
      },
      email: {
        title: "Email",
        placeholder: "john.doe@example.com",
        errors: {
          required: "Email is required",
          validEmail: "Input valid email address",
        },
      },
      phone: {
        title: "Phone number",
        placeholder: "+358 40 1234 567",
        errors: {
          required: "Phone number is required",
        },
      },
      recaptcha: {
        title: "Verification",
        errors: {
          required: "Please fulfill the verification.",
        },
      },
      extra: {
        title: "Additional information",
        placeholder:
          "Additional information or requests for additional services like outdoor hot tub, special saunas or other activities during your stay.",
      },
    },
  },
};

export default i18n;
