module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"toijalantila","defaultLang":"fi","langs":["fi","en-gb","de-de"],"shortenUrlLangs":true,"previews":false,"pages":[{"type":"Frontpage","match":"/:lang","component":"/opt/build/repo/src/templates/frontpage.tsx"},{"type":"Cabins","match":"/:lang/mokit","component":"/opt/build/repo/src/templates/cabins.tsx","langs":["fi"]},{"type":"Cabins","match":"/:lang/cabins","component":"/opt/build/repo/src/templates/cabins.tsx","langs":["en-gb","de-de"]},{"type":"Cabin","match":"/:lang/mokki/:uid","component":"/opt/build/repo/src/templates/cabin.tsx","langs":["fi"]},{"type":"Cabin","match":"/:lang/cabin/:uid","component":"/opt/build/repo/src/templates/cabin.tsx","langs":["en-gb","de-de"]},{"type":"Gallery","match":"/:lang/galleria","component":"/opt/build/repo/src/templates/gallery.tsx","langs":["fi"]},{"type":"Gallery","match":"/:lang/gallery","component":"/opt/build/repo/src/templates/gallery.tsx","langs":["en-gb","de-de"]},{"type":"Toijala_farm","match":"/:lang/toijalan-tila","component":"/opt/build/repo/src/templates/basic.tsx","langs":["fi"]},{"type":"Toijala_farm","match":"/:lang/farm","component":"/opt/build/repo/src/templates/basic.tsx","langs":["en-gb","de-de"]},{"type":"Services","match":"/:lang/palvelut","component":"/opt/build/repo/src/templates/basic.tsx","langs":["fi"]},{"type":"Services","match":"/:lang/services","component":"/opt/build/repo/src/templates/basic.tsx","langs":["en-gb","de-de"]},{"type":"History","match":"/:lang/historia","component":"/opt/build/repo/src/templates/basic.tsx","langs":["fi"]},{"type":"History","match":"/:lang/history","component":"/opt/build/repo/src/templates/basic.tsx","langs":["en-gb","de-de"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-SPDQ8DE0MX"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
